import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactus } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] dark:from-[#e8eaea] dark:to-[#d7d9d9] p-4 lgl:p-8 rounded-lg shadow-shadowOne dark:shadow-shadowTwo flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactus}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white dark:text-black">Eplusapp</h3>
        <p className="text-lg font-normal text-gray-400 dark:text-gray-800">
        Eplusapp is  software development company
        </p>
        <p className="text-base text-gray-400 dark:text-gray-800 tracking-wide">
        It is with great pleasure that Eplusapp announces the announcement of its long awaited secondary share offering.
        </p>
        <p className="text-base text-gray-400 dark:text-gray-600 flex items-center gap-2">
          Phone:{" "}
          <span className="text-lightText dark:text-black">
            +2519-18-48-47-82
          </span>
        </p>
        <p className="text-base text-gray-400 dark:text-gray-600 flex items-center gap-2">
          Email:{" "}
          <span className="text-lightText dark:text-black">
            www.info88@gmail.com
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">see more</h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <FaFacebookF />
          </span>
          <span className="bannerIcon">
            <FaTwitter />
          </span>
          <span className="bannerIcon">
            <FaLinkedinIn />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
