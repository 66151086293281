import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../layouts/Title";
import { projectTwo, projectThree } from "../../assets/index";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import NormalStockCard from "./NormalStockCard";
import { useNavigate } from "react-router-dom";
import { viewStocks } from "../../actions/stockAction";
import Slider from "react-slick";
import Loading from "../loadingPage/loading";
import { Stock } from "../data/stock";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] dark:bg-[#c2c1c3] dark:hover:bg-[#979698] hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute -top-5 right-0  shadow-shadowOne dark:shadow-shadowTwo cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowRight className="dark:text-black" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black dark:bg-[#c2c1c3] dark:hover:bg-[#979698] duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute -top-5 right-20 shadow-shadowOne dark:shadow-shadowTwo cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowLeft className="dark:text-black" />
    </div>
  );
}
const NormalStock = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "#ff014f",
                borderRadius: "50%",
                cursor: "pointer",
              }
            : {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "gray",
                borderRadius: "50%",
                cursor: "pointer",
              }
        }
      ></div>
    ),
  };
  const dispatchStock = useDispatch();

  useEffect(() => {
    dispatchStock(viewStocks());
  }, []);
  const { stocks } = useSelector((state) => state.stocks);
  const navigate = useNavigate();
  const stockHandler = (id) => {
    navigate("/stock/" + id);
  };

  return (
    <section id="አክሲዮን" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title
          title="You want to buy new shares or transfer your existing ones"
          des="Normal Stock"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-1 gap-6 xl:gap-14">
        <Slider {...settings}>
          {Stock?.length > 0 ? (
            Stock?.map((stock, index) => (
              <div onClick={() => stockHandler(stock?.id)}>
                <NormalStockCard
                  key={index}
                  title={stock?.title}
                  des={stock?.description}
                  src={stock?.logo}
                  //src={`${StockBaseUrl}/images/${stock?.logo}`}
                />
              </div>
            ))
          ) : (
            <Loading />
          )}
        </Slider>
      </div>
    </section>
  );
};

export default NormalStock;
