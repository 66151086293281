import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../baseUrl/toast";
import StockBaseUrl from "../baseUrl/BaseUrl";

export const addPayment = createAsyncThunk(
  "payment",
  async (paymentData, thunkAPI) => {
    try {
      console.log("paymentData : ", paymentData);
      let URL = `http://localhost:11219/EplusappPayment-API/payment/create`;

      let response = await axios.post(URL, paymentData, {
        headers: {
          Authorization: `Bearer CHASECK_TEST-LGc7GrVmx0P3cn3cxu6l93wDD2zIMISR`,
        },
      });
      console.log("response : ", response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
