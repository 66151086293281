import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BidsModel from "../models/popup-model";
import LoginForm from "../users/userLogin";
import { useNavigate } from "react-router-dom";
import { addPayment } from "../../actions/paymentAction";
const Pay = ({ amount }) => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const [showLogin, setShowLogin] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const {
  //   handleSubmit,
  //   register,
  //   formState: { errors },
  // } = useForm();

  //   if (amount === "") {
  //   setErrMsg("የአክሲዮን ብዛት ያስገቡ!");
  // } else if (amount <= 0) {
  //   setErrMsg("ውድ ደንበኛችን የሚገዙት አክሲዮን ቢያንስ ከ 1 ማነስ የለበትም ");
  // } else {
  //   //setSuccessMsg(`ጫራታው መሉ በሙሉ ተሳክትዋል!`);
  //   setErrMsg("");
    
  // }
 // console.log("Payment Detail Data : ", amount);
  const onSubmit = (data) => {
    const newData = { ...data, amount: data };
    //console.log("passed Data :", newData);
    dispatch(addPayment(newData));
  };
  const { payment, success } = useSelector((state) => state.payment);
  //console.log("paymentDetail : ", payment.callback_url);
  if (success == true) {
    window.location.replace(`${payment.callback_url}`);
  }
  return (
    <>
      {/* <form> */}
      {/* <input type="hidden" {...register("amount")} /> */}
      {userInfo ? (
        <button
          type="submit"
          onClick={() => onSubmit(amount)}
          className="w-full h-12 bg-[#141518] dark:bg-[#fafafb] rounded-lg text-base dark:text-lightText2 text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
        >
          Pay Now
        </button>
      ) : (
        <>
             {/* {errMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne  dark:from-[#e1e1e1] dark:to-[#dedcdc] dark:shadow-shadowTwo text-center text-orange-500 text-base tracking-wide animate-bounce">
                    {errMsg}
                  </p>
                )} */}
        <button
          onClick={() => setShowLogin(true)}
          type="submit"
          className="w-full h-12 bg-[#141518] dark:bg-[#fafafb] rounded-lg text-base dark:text-lightText2 text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
        >
          Pay Now
        </button>
        </>
      )}
      {/* </form> */}
      <BidsModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <LoginForm />
      </BidsModel>
    </>
  );
};
export default Pay;
