import { createSlice } from "@reduxjs/toolkit";

import { loginUser } from "../actions/userAction";

// initial state
const initialState = {
  loading: false,
  users: [],
  user: {},
  isLogin: false,
  error: false,
  success: false,
  message: null,
};

// users slice
const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    // user login
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
      // console.log("user pending");
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload.user;
      state.success = true;
      state.error = false;
      state.isLogin = true;
      state.message = payload.message;
      // console.log(payload.user);
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      // state.message = payload.message;
      state.success = false;
      state.isLogin = false;
      // console.log("user rejected");
    },
  },
});
// export
export default usersSlice.reducer;
