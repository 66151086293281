import { data } from "autoprefixer";
import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";

const Timer = ({ countDate }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState();

  let interval = useRef();
  const startTimer = () => {
    console.log("passed Date : ", countDate);
    const countdownDate = new Date(countDate).getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <>
      <div className="w-full h-auto flex flex-col lgl:flex-row justify-center">
        <div className="w-full lgl:w-[95%] h-full bg-gradient-to-r from-[#171718] to-[#111112] dark:from-[#f0f3f3] dark:to-[#e6e9e9] p-8 rounded-lg dark:shadow-shadowTwo flex flex-col md:flex-row lgl:flex-col gap-8 justify-center items-center">
          <div className=" justify-center items-center text-center">
            <h3 className="text-2xl font-bold">E+up</h3>
            <p className="text-base tracking-wide text-gray-500">
            Time left for the bid to close 
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div>
                <span className="bannerIcon">{timerDays}</span>
                <h2 className="text-base uppercase font-titleFont m-2">Days</h2>
              </div>
              <div>
                <span className="bannerIcon">{timerHours}</span>
                <h2 className="text-base uppercase font-titleFont m-2">Hours</h2>
              </div>
              <div>
                <span className="bannerIcon">{timerMinutes}</span>
                <h2 className="text-base uppercase font-titleFont m-2">Minutes</h2>
              </div>
              <div>
                <span className="bannerIcon">{timerSeconds}</span>
                <h2 className="text-base uppercase font-titleFont m-2">Seconds</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Timer;
