import React, { useState, useEffect } from "react";
import Title from "../layouts/Title";
import StockDescription from "./StockDescription";
import Layout from "../navLayout/NavLayout";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import BidsModel from "../models/popup-model";
import LoginForm from "../users/userLogin";
import Invoicer from "../invoicer/invoicer";
import VerticalChart from "../verticalChart/VerticalChart";
import Timer from "../timer/Timer";
import Pay from "../payment/pay";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

const StockDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 3000 });
  }, []);
  const [stockNumber, setStockNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [payTotalBirr, setTotalBirr] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const [showLogin, setShowLogin] = useState(false);
  const [showInvoicer, setInvoicer] = useState(false);
  const { stocks } = useSelector((state) => state.stocks);
  // const {
  //   handleSubmit,
  //   register,
  //   formState: { errors },
  // } = useForm();

  // // on submit
  // const onSubmit = (data) => {
  //   // console.log(data);
  // };
  // const handleSend = (e) => {
  //   e.preventDefault();
  // if (totalBirr === "") {
  //   setErrMsg("የአክሲዮን ብዛት ያስገቡ!");
  // } else if (totalBirr <= 0) {
  //   setErrMsg("ውድ ደንበኛችን የሚገዙት አክሲዮን ቢያንስ ከ 1 ማነስ የለበትም ");
  // } else {
  //   setSuccessMsg(`ጫራታው መሉ በሙሉ ተሳክትዋል!`);
  //   setErrMsg("");
  //   setStockNumber("");
  // }
  const totalBirr = 1000 * amount;
  // };
  const { stock } = useSelector((state) => state.stock);

  return (
    <Layout>
      <VerticalChart />
      <section
        id="አክሲዮን"
        className="w-full py-20 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Title des="Eplusapp" />
        </div>
        <div className="w-full">
          <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
            <StockDescription />
            <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b]  dark:from-[#f0f3f3] dark:to-[#e6e9e9] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowOne dark:shadow-shadowTwo">
              <div
                className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5"
                // onSubmit={handleSubmit(onSubmit)}
              >
                {errMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne  dark:from-[#e1e1e1] dark:to-[#dedcdc] dark:shadow-shadowTwo text-center text-orange-500 text-base tracking-wide animate-bounce">
                    {errMsg}
                  </p>
                )}
                {successMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne  dark:from-[#e1e1e1] dark:to-[#dedcdc] dark:shadow-shadowTwo text-center text-green-500 text-base tracking-wide animate-bounce">
                    {successMsg}
                  </p>
                )}
                <div className="w-full flex flex-col lgl:flex-row gap-10">
                  <div className="w-full flex gap-4">
                    <p className="text-sm text-gray-400 uppercase tracking-wide dark:text-gray-800">
                      Number of Stock
                    </p>
                    <input
                      onChange={(e) => setAmount(e.target.value)}
                      // value={stockNumber}
                      className={`${
                        errMsg === "Phone number is required!" &&
                        "outline-designColor"
                      } contactInput`}
                      type="text"
                    />
                  </div>
                </div>
                <div className="relative flex gap-4">
                  <p className="text-sm text-gray-400 dark:text-gray-800 uppercase tracking-wide">
                    Total Birr
                  </p>
                  <input
                    onChange={(e) => setTotalBirr(e.target.value)}
                    value={1000 * amount}
                    readOnly
                    className={`${
                      errMsg === "Please give your Email!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="email"
                  />
                </div>
                <div>
                  <Pay amount={totalBirr} />
                </div>
              </div>
              <Timer countDate="2023-11-27T00:00:00.000Z" />
            </div>
          </div>
        </div>
      </section>
      {/* <BidsModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <LoginForm />
      </BidsModel>
      <BidsModel isVisible={showInvoicer} onClose={() => setInvoicer(false)}>
        <Invoicer />
      </BidsModel> */}
    </Layout>
  );
};
export default StockDetail;
