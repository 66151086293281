import React from "react";
import { Discount, ArrowUp, robot } from "../../assets";
const FirstPage = () => {
  return (
    <section id="መግቢያ" className={`flex md:flex-row flex-col sm:py-16 py-6`}>
      <div
        className={`flex-1 flex justify-center items-start flex-col xl:px-0 sm:px-16 px-6`}
      >
        <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
          <img src={Discount} alt="discount" className="w-[32px] h-[32px]" />
          <p
            className={`font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px] ml-2`}
          >
            <span className="text-white dark:text-black">20%</span> discount for{" "}
            <span className="text-white dark:text-black">1 month</span> only
          </p>
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-4xl text-white dark:text-black ss:leading-[100.8px] leading-[50px]">
            Eplusapp <br className="sm:block hidden" />{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#61E1E9] to-[#dbedef] dark:from-[#209ca0] dark:to-[#90d1d3]">
              Stock
            </span>{" "}
          </h1>
          <div className="md:flex hidden md:mr-4 mr-0">
            {/* GetStarted icon */}
            <div
              className={`flex relative justify-center items-center w-[140px] h-[140px] rounded-full p-[2px] cursor-pointer`}
              style={{
                background:
                  "linear-gradient(157.81deg,#def9fa -43.27%,#bef3f5 -21.24%,#9dedf0 12.19%,#7de7eb 29.82%,#5ce1e6 51.94%,#33bbcf 90.29%)",
              }}
            >
              <div className="flex justify-center items-center flex-col bg-[#00040f] dark:bg-[#EAE7E8] w-[100%] h-[100%] rounded-full">
                <div className={`flex justify-center items-start flex-row`}>
                  <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                    <span className="text-gradient">E</span>
                  </p>
                  <img
                    src={ArrowUp}
                    alt="arrow-up"
                    className="w-[23px] h-[23px] object-contain"
                  />
                </div>

                <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                  <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#61E1E9] to-[#f4f8f9] dark:from-[#0f6065] dark:to-[#8bc9d8]">
                    plus
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="font-poppins font-semibold ss:text-[68px] text-4xl text-white dark:text-black ss:leading-[100.8px] leading-[75px] w-full">
        Payment options.
        </h1>
        <p
          className={`font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px] max-w-[470px] mt-5`}
        >
          Eplusapp uses all payment options. For now, Amhara Bank, Ethiopia Commercial Bank and
          You can buy or bid on the stocks of your choice using Buna Bank mainly.
        </p>
      </div>

      <div
        className={`flex-1  flex justify-center items-center md:my-0 my-10 relative`}
      >
        <img
          src={robot}
          alt="billing"
          className="w-[100%] h-[100%] relative z-[5]"
        />

        <div
          className="absolute z-[0] w-[40%] h-[35%] top-0"
          style={{
            filter: "blur(900px)",
            background: "linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%)",
          }}
        />
        <div
          className="absolute z-[1] w-[80%] h-[80%] rounded-full bottom-40"
          style={{
            filter: "blur(750px)",
            background: "rgba(255, 255, 255, 0.6)",
          }}
        />
        <div
          className="absolute z-[0] w-[80%] h-[80%] right-20 bottom-20"
          style={{
            filter: "blur(123px)",
            background:
              "linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%)",
          }}
        />
      </div>

      <div className={`md:hidden flex justify-center items-center relative`}>
        {/* GetStarted icon */}
        <div
          className={`flex justify-center items-center w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}
        >
          <div className="flex justify-center items-center flex-col bg-[#61E1E9] w-[100%] h-[100%] rounded-full">
            <div className={`flex justify-center items-start flex-row`}>
              <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                <span className="text-gradient">Get</span>
              </p>
              <img
                src={ArrowUp}
                alt="arrow-up"
                className="w-[23px] h-[23px] object-contain"
              />
            </div>

            <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
              <span class="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                Started
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FirstPage;
