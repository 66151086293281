import { createSlice } from "@reduxjs/toolkit";
import { viewAllBids, getSingleBids } from "../actions/bidAction";

// initial state
const initialState = {
  loading: false,
  bids: [],
  bids: {},
  error: false,
  success: false,
};

const bidSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: {
    // view all bids
    [viewAllBids.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [viewAllBids.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.bids = payload;
      state.error = false;
      //  console.log(payload)
    },
    [viewAllBids.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },

    // view single bids
    [getSingleBids.pending]: (state) => {
      state.loading = true;
      state.error = false;
      //console.log("biding pending status");
    },
    [getSingleBids.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.bids = payload;
    },
    [getSingleBids.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
    },
  },
});

// export
export default bidSlice.reducer;
