import { createSlice } from "@reduxjs/toolkit";
import { viewStocks, getStocks } from "../actions/stockAction";

// initial state
const initialState = {
  loading: false,
  stocks: [],
  stocks: {},
  error: false,
  success: false,
  message: null,
};

// eplusapp stock slice
const stockSlice = createSlice({
  name: "stocks",
  initialState,
  reducers: {},
  extraReducers: {
    // view users
    [viewStocks.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [viewStocks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.stocks = payload;
    },
    [viewStocks.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get single stock
    [getStocks.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getStocks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.stocks = payload;
    },
    [getStocks.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default stockSlice.reducer;
