export const Stock = [
  {
    id: 1,
    title: "Double Storey Elevation | Two Storey House Elevation",
    description:
      "Basically Double floor house are designed for two families. We build smaller houses with lots of luxurious upgrades and details. Double Storey house can be two types: Duplex or Individual.",
    logo: "/stock/house-1.jpg",
    type: "stock",
    unitPrice: "12,345,50",
    startingDate: "07-20-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 2,
    title: "Toyota car",
    description:
      "TrueCar has ranked the best Toyota cars, trucks & SUVs for July 2024. Research the top-performing vehicles on our list before heading to the dealership!",
    logo: "/stock/toyota.jpg",
    type: "stock",
    unitPrice: "7,345,509",
    startingDate: "01-02-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 3,
    title: "Best house",
    description:
      "Set in the coastal town of Mangalore, this villa defines and honours the unique personality of the client. It is an amalgamation of Neo-Classical elements without leaving the comforts of the contemporary lifestyle, the regional design element and the spatial requirements of the client.",
    logo: "/stock/house-2.jpeg",
    type: "stock",
    unitPrice: "12,345,50",
    startingDate: "07-20-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 4,
    title: "Bahir Dar Hotels",
    description:
      "Prices are the average nightly price provided by our partners and may not include all taxes and fees. Taxes and fees that are shown are estimates only. Please see our partners for more details.",
    logo: "/stock/hotels.jpg",
    type: "stock",
    unitPrice: "82,345,50",
    startingDate: "07-10-2024",
    closingDate: "8-02-2025",
  },
];
