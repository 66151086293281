import { createSlice } from "@reduxjs/toolkit";
import { addPayment } from "../actions/paymentAction";

// initial state
const initialState = {
  loading: false,
  //payment: [],
  payment: {},
  error: false,
  success: false,
  message: null,
};

// eplusapp stock slice
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: {
    // create payment state
    [addPayment.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [addPayment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.payment = payload;
    },
    [addPayment.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default paymentSlice.reducer;
