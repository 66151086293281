import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStocks, viewStocks } from "../../actions/stockAction";
import { getSingleStock } from "../../actions/singleStockAction";
import { useParams } from "react-router-dom";
import StockBaseUrl from "../../baseUrl/BaseUrl";
import { Stock } from "../data/stock";
import { logo } from "../../assets";

const StockDescription = () => {
  const dispatchStock = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatchStock(getSingleStock(id));
  }, []);
  //const { stock } = useSelector((state) => state.stock);
  //console.log("image name :", stock.logo);
  const stock = Stock.find((stock) => stock.id === parseInt(id));
  return (
    <div className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne dark:shadow-shadowTwo flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] dark:from-bgBodyColor dark:to-[#f0f3f3]  group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 dark:hover:from-gray-300 dark:hover:gray-300 transition-colors duration-1000">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        // src={`${StockBaseUrl}images/${stock?.logo}`}
        src={stock?.logo}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white dark:text-black">
          {stock?.name}
        </h3>
        <p className="text-base text-gray-400 tracking-wide dark:text-gray-900">
          {stock?.description}
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2 dark:text-gray-900">
          type:{" "}
          <span className="text-lightText dark:to-lightText2">
            {stock?.type}
          </span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2 dark:text-gray-900">
          unit price:{" "}
          <span className="text-lightText dark:to-lightText2">
            {stock?.unitPrice}
          </span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2 dark:text-gray-900">
          starting date:{" "}
          <span className="text-lightText dark:to-lightText2">
            {stock?.startingDate}
          </span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2 dark:text-gray-900">
          end date:{" "}
          <span className="text-lightText  dark:to-lightText2">
            {stock?.closingDate}
          </span>
        </p>
      </div>
    </div>
  );
};

export default StockDescription;
