import React from "react";
import { BsGithub } from "react-icons/bs";
import { FaGlobe } from "react-icons/fa";
import StockBaseUrl from "../../baseUrl/BaseUrl";

const BidCard = ({ title, des, src }) => {
  return (
    <div className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne dark:shadow-shadowTwo flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] dark:from-bgBodyColor dark:to-[#f0f3f3]  group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 dark:hover:from-gray-300 dark:hover:gray-300 transition-colors duration-1000">
      <div className="w-full h-[80%] overflow-hidden rounded-lg">
        <img
          className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          //  src={`${StockBaseUrl}images/${src}`}
          src={src}
          alt="src"
        />
      </div>
      <div className="w-full mt-5 flex flex-col  gap-6">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-normal">
              {title}
            </h3>
            <div className="flex gap-2">
              <span className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                <BsGithub />
              </span>
            </div>
          </div>
          <p className="text-sm tracking-wide mt-3 hover:text-gray-100 dark:text-gray-900 duration-300">
            {des}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BidCard;
