import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewStocks } from "../../actions/stockAction";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Eplusapp Stock",
    },
  },
};

const VerticalChart = () => {
  const dispatch = useDispatch();
  const { stocks } = useSelector((state) => state.stocks);
  console.log("vertical stock data :", stocks);
  const [data, setData] = useState({
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "share holders",
        data: [4,3,2,7,5,4,3],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(25, 90, 13, 0.5)",
      },
      {
        label: "solde stock",
        data: [2,4,1,3,3,5,2,4],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(118, 204, 107, 0.5)",
      },
    ],
  });
  //useEffect(() => {
    const labelSet = [];
    const dataSet1 = [];
    const dataSet2 = [];
    dispatch(viewStocks());
    if (Array.isArray(stocks)) {
      const result = stocks.map(
        (item, index) => (
          dataSet1.push(item.quantity),
          dataSet2.push(item.id),
          labelSet.push(item.name)
        )
      );
      //   console.log("new array : ", dataSet1);
      setData({
        //["ሰኞ", "ማክሰኞ", "ዕሮብ", "ሀሙስ", "ዓርብ", "ቅዳሜ", "እሁድ"]
        labels: labelSet,
        datasets: [
          {
            label: "share holders",
            data: dataSet1,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(104, 107, 110, 0.5)",
            tension: 0.4,
            fill: true,
            pointStyle: "star",
          },
          {
            label: "solde stock",
            data: dataSet2,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(20,21,24, 0.5)",
            tension: 0.4,
            fill: true,
            pointStyle: "rect",
          },
        ],
      });
    } else {
      console.error("stocks is not an array.");
    }
 // }, []);

  return (
    <div className="w-full flex justify-center">
      <div style={{ width: "90%", height: "50%" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};
export default VerticalChart;
