export const Bid = [
  {
    id: 1,
    title: "desktop computer",
    description: "Desktop Computer Prices In Bahir Dar",
    logo: "/bids/desctop-1.jpg",
    type: "bids",
    unitPrice: "12,345,50",
    startingDate: "07-20-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 2,
    title: "Growing Fiddle Leaf Figs Indoors",
    description:
      "The tree is usually used as the focal point in a large room. While fiddle leaf figs can be a bit picky about how they are cared for, the tree does fine once you figure out what to do.",
    logo: "/bids/leaf.jpg",
    type: "bids",
    unitPrice: "87,345,50",
    startingDate: "07-20-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 3,
    title: "Best Smart Tv",
    description:
      "Since most TVs nowadays are smart, you have plenty of smart TV choices in 2024.",
    logo: "/bids/tv.jpg",
    type: "bids",
    unitPrice: "87,345,50",
    startingDate: "07-20-2024",
    closingDate: "08-12-2025",
  },
  {
    id: 4,
    title: "Tables",
    description:
      "There is a wide range of options on how we can establish the place, shape or our dining table size, the most common are usually the dining tables",
    logo: "/bids/teble.jpg",
    type: "bids",
    unitPrice: "345,50",
    startingDate: "09-20-2024",
    closingDate: "09-30-2024",
  },
  {
    id: 5,
    title: "Best Grass",
    description:
      "Gromax India Lawn Grass Seeds Hybrid Best For Your Beautiful Home Gardening And Farmhouse",
    logo: "/bids/gras.jpg",
    type: "bids",
    unitPrice: "934,50",
    startingDate: "07-03-2024",
    closingDate: "08-11-2024",
  },
  {
    id: 6,
    title: "desktop computer",
    description: "Desktop Computer Prices In Bahir Dar",
    logo: "/bids/desctop-1.jpg",
    type: "bids",
    unitPrice: "79,45,50",
    startingDate: "09-20-2024",
    closingDate: "10-12-2024",
  },
];
