import React, { useState, useEffect, memo } from "react";
import Title from "../layouts/Title";
import BidDescription from "./BidDescription";
import Layout from "../navLayout/NavLayout";
import HorizontalChart from "../horizontalChart/HorizontalChart";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleBids } from "../../actions/bidAction";
import { useForm } from "react-hook-form";
import BidsModel from "../models/popup-model";
import LoginForm from "../users/userLogin";
import Timer from "../timer/Timer";
import { addPayment } from "../../actions/paymentAction";
import Pay from "../payment/pay";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

const BidDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 3000 });
  }, []);

  const { bids } = useSelector((state) => state.bids);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [amount, setAmount] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const [totalBirr, setTotalBirr] = useState("");
  const stockTotal = amount * stockNumber;

  return (
    <Layout>
      <HorizontalChart />
      <section
        id="አክሲዮን"
        className="w-full py-20 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Title
            title="Read the auction thoroughly before bidding"
            des="Eplusapp"
          />
        </div>
        <div className="w-full">
          <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
            <BidDescription />
            <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] dark:from-[#f0f3f3] dark:to-[#e6e9e9] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowOne dark:shadow-shadowTwo">
              <div
                className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5"
                // onSubmit={handleSubmit(onSubmit)}
              >
                {errMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                    {errMsg}
                  </p>
                )}
                {successMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                    {successMsg}
                  </p>
                )}
                <div className="w-full flex flex-col lgl:flex-row gap-10">
                  <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                    <p className="text-sm text-gray-400 uppercase tracking-wide">
                      biding price
                    </p>
                    <input
                      onChange={(e) => setAmount(e.target.value)}
                      className={`${
                        errMsg === "biding price!" && "outline-designColor"
                      } contactInput`}
                      type="text"
                    />
                  </div>
                  <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                    <p className="text-sm text-gray-400 uppercase tracking-wide">
                      number of bid
                    </p>
                    <input
                      onChange={(e) => setStockNumber(e.target.value)}
                      className={`${
                        errMsg === "CPOPrice is required!" &&
                        "outline-designColor"
                      } contactInput`}
                      type="text"
                    />
                  </div>
                </div>
                <div className="relative flex gap-4">
                  <p className="text-sm text-gray-400 uppercase tracking-wide">
                    total birr
                  </p>
                  <input
                    onChange={(e) => setTotalBirr(e.target.value)}
                    value={amount * stockNumber}
                    readOnly
                    className={`${
                      errMsg === "Please give your Email!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="email"
                  />
                </div>
                {/* <div>
                  <input
                    type="hidden"
                    onChange={(e) => setCurrency(e.target.value)}
                  />
                </div> */}
                <Pay amount={stockTotal} />
              </div>
              <Timer countDate="2023-10-27T00:00:00.000Z" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default BidDetail;
