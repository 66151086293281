import { createSlice } from "@reduxjs/toolkit";
import { getSingleStock } from "../actions/singleStockAction";

// initial state
const initialState = {
  loading: false,
  stock: [],
  stock: {},
  error: false,
  success: false,
  message: null,
};

// eplusapp stock slice
const singleStockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {},
  extraReducers: {
    // get single stock
    [getSingleStock.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getSingleStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.stock = payload;
    },
    [getSingleStock.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default singleStockSlice.reducer;
