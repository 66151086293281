import React from "react";
import Layout from "../navLayout/NavLayout";
import NormalStock from "../normalStock/NormalStock";
import Bids from "../bids/Bids";
import Contact from "../contact/contact";
import FirstPage from "../firstPage/FirstPage";
import BackTotop from "../backToTop/BackToTop";
const HomePage = () => {
  return (
    <Layout>
      <div className="max-w-screen-xl mx-auto">
        <FirstPage />
        <NormalStock />
        <Bids />
        <Contact />
        <BackTotop />
      </div>
    </Layout>
  );
};
export default HomePage;
