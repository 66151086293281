import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import BidDetail from "./components/bidDetail/BidDetail";
import StockDetail from "./components/stockDetail/StockDetail";
import Invoicer from "./components/invoicer/invoicer";

function App() {
  return (
    <div className="w-full h-auto bg-bodyColor dark:bg-bgBodyColor text-lightText dark:text-slate-900 px-4">
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/bids/:id" element={<BidDetail />}></Route>
        <Route path="/stock/:id" element={<StockDetail />}></Route>
        <Route path="/invoicer" element={<Invoicer />}></Route>
      </Routes>
    </div>
  );
}

export default App;
