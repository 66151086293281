// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../baseUrl/toast";
import StockBaseUrl from "../baseUrl/BaseUrl";

export const getBidder = createAsyncThunk("bidders", async (id, thunkAPI) => {
  try {
    let URL = `${StockBaseUrl}stockapi/bidders/${id}`;
    // let response = await createContact(message);
    let response = await axios.get(URL);
    //  console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    //console.log("Error", e.response.data);
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
export const viewBidders = createAsyncThunk("bidders", async (thunkAPI) => {
  try {
    let URL = `${StockBaseUrl}stockapi/bidders/all`;
    // let response = await createContact(message);
    let response = await axios.get(URL);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    //console.log("Error", e.response.data);
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
