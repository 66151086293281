import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../layouts/Title";
import { projectTwo, projectThree } from "../../assets/index";
import BidCard from "./BidCard";
import { useNavigate, useParams } from "react-router-dom";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import Slider from "react-slick";
import { getSingleBids, viewAllBids } from "../../actions/bidAction";
import Loading from "../loadingPage/loading";
import { Bid } from "../data/bid";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black dark:bg-[#c2c1c3] dark:hover:bg-[#979698] duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute -top-5 left-20  shadow-shadowOne dark:shadow-shadowTwo cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowDown className="dark:text-black" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black duration-300 dark:bg-[#c2c1c3] dark:hover:bg-[#979698] rounded-md text-2xl text-gray-400 flex justify-center items-center absolute -top-5 left-0 shadow-shadowOne dark:shadow-shadowTwo cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowUp className="dark:text-black" />
    </div>
  );
}
const Bids = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(viewAllBids());
  }, []);
  const { bids } = useSelector((state) => state.bids);
  const bidHandler = (id) => {
    navigate("/bids/" + id);
  };

  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    vertical: true,
    // verticalSwiping: true,
    // swipeToSlide: true,
    rows: 1,
    slidesPerRow: 3,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "#ff014f",
                borderRadius: "50%",
                cursor: "pointer",
              }
            : {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "gray",
                borderRadius: "50%",
                cursor: "pointer",
              }
        }
      ></div>
    ),
  };

  return (
    <section id="አክሲዮን" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title
          title="All shares are being bid at a best price"
          des="Bid Stock"
        />
      </div>
      <div
        className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-1 gap-6 xl:gap-14"
        // onClick={() => bidHandler()}
      >
        <Slider {...settings}>
          {Bid?.length > 0 ? (
            Bid?.map((bid, index) => (
              <div onClick={() => bidHandler(bid?.id)}>
                <BidCard
                  title={bid?.title}
                  des={bid?.description}
                  src={bid?.logo}
                />
              </div>
            ))
          ) : (
            <Loading />
          )}
        </Slider>
      </div>
    </section>
  );
};

export default Bids;
