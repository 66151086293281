import { createSlice } from "@reduxjs/toolkit";
import { viewBidders, getBidder } from "../actions/bidderAction";

// initial state
const initialState = {
  loading: false,
  bidders: [],
  bidders: {},
  error: false,
  success: false,
  message: null,
};

// eplusapp stock slice
const biddersSlice = createSlice({
  name: "bidders",
  initialState,
  reducers: {},
  extraReducers: {
    // view bids
    [viewBidders.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [viewBidders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.bidders = payload;
    },
    [viewBidders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get single bid
    [getBidder.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getBidder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.bidders = payload;
    },
    [getBidder.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// export
export default biddersSlice.reducer;
