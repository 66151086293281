import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewBidders } from "../../actions/bidderAction";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
  Title,
  Tooltip,
  Legend
);
const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 0.5,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "level of biding",
    },
  },
};

const HorizontalChart = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "CPO price",
        data: [3, 4, 1, 5, 6, 4, 2],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(25, 90, 13, 0.5)",
      },
      {
        label: "Bidding price",
        data: [4, 3, 1, 5, 5, 6, 2],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(118, 204, 107, 0.5)",
      },
    ],
  });
  const { bidders } = useSelector((state) => state.bidders);

  //useEffect(() => {
  const labelSet = [];
  const dataSet1 = [];
  const dataSet2 = [];
  dispatch(viewBidders());
  if (Array.isArray(bidders)) {
    const result = bidders.map(
      (item, index) => (
        labelSet.push(item.firstName),
        dataSet1.push(item.CPOPrice),
        dataSet2.push(item.biddingPrice)
      )
    );
    //   console.log("new array : ", dataSet1);
    setData({
      //["ሰኞ", "ማክሰኞ", "ዕሮብ", "ሀሙስ", "ዓርብ", "ቅዳሜ", "እሁድ"]
      labels: labelSet,
      datasets: [
        {
          label: "CPO price",
          data: dataSet1,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(104, 107, 110, 0.5)",
          tension: 0.4,
          fill: true,
          pointStyle: "star",
        },
        {
          label: "Bidding price",
          data: dataSet2,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(20,21,24, 0.5)",
          tension: 0.4,
          fill: true,
          pointStyle: "rect",
        },
      ],
    });
  } else {
    console.error("bids is not an array.");
  }
  // }, []);

  return (
    <div className="w-full flex justify-center">
      <div style={{ width: "90%", height: "50%" }}>
        {/* {console.log("data", data)} */}
        <Line data={data} options={options} />
      </div>
    </div>
  );
};
export default HorizontalChart;
