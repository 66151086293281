import { createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import stockReducer from "../reducer/stockReducer";
import bidsReducer from "../reducer/bidsReducer";
import userReducer from "../reducer/userReducer";
import biddersReducer from "../reducer/biddersReducer";
import singleStockReducer from "../reducer/singleStockReducer";
import paymentReducer from "../reducer/paymentReducer";

const store = configureStore({
  reducer: {
    stocks: stockReducer,
    stock: singleStockReducer,
    user: userReducer,
    bidders: biddersReducer,
    bids: bidsReducer,
    payment: paymentReducer,
  },
});

export default store;
