// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../baseUrl/toast";
import StockBaseUrl from "../baseUrl/BaseUrl";

export const getSingleStock = createAsyncThunk(
  "stock",
  async (id, thunkAPI) => {
    console.log("passed Id : ", id);
    try {
      let URL = `http://localhost:11214/stockapi/stocks/${id}`;
      // let response = await createContact(message);
      let response = await axios.get(URL);
      console.log("single stock action :", response);
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      //console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
