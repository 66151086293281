export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "መግቢያ",
  },
  {
    _id: 1002,
    title: "Stock",
    link: "አክሲዮን",
  },
  {
    _id: 1003,
    title: "Contact",
    link: `መልዕክት`,
  },
];
