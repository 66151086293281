import React from "react";

const FooterBottom = () => {
  return (
    <div className="w-full py-10">
      <p className="text-center text-gray-500 text-base">  
      Copyright © {new Date().getFullYear()} Eplusapp. All rights reserved.
      </p>
    </div>
  );
};

export default FooterBottom;
