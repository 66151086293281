// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../baseUrl/toast";
import StockBaseUrl from "../baseUrl/BaseUrl";

export const viewAllBids = createAsyncThunk("bids", async (thunkAPI) => {
  try {
    let BaseUrl = `http://localhost:11214/stockapi/bids/all`;
    let response = await axios.get(BaseUrl);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
export const getSingleBids = createAsyncThunk("bids", async (id, thunkAPI) => {
  try {
    let bidUrl = `${StockBaseUrl}stockapi/bids/${id}`;
    let response = await axios.get(bidUrl);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
